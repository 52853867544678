<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <b-form>
        <div>
          <h1 class="mr-sm-4 header-tablepage">PRIVILEGE DETAIL</h1>
        </div>
        <b-tabs class="mt-3">
          <b-tab title="Set Up Privileges">
            <div class="p-3 bg-white">
              <FormPrivilege
                :form="form"
                :v="$v"
                :isDisable="isDisable"
                :checkRedeem="checkRedeem"
                :quantityIsRedeem="quantityIsRedeem"
                :id="parseInt(id)"
              />
              <div class="panelborder">
                <div class="title-tabs">Privilege List</div>
                <b-container class="no-gutters bg-white p-2 p-sm-3">
                  <b-row class="align-items-center mb-2">
                    <b-col md="8" cols="6">
                      <b-button
                        class="btn-select-branch w-100 w-sm-unset text-nowrap"
                        @click.prevent="selectPrivilegeDetail"
                      >
                        Select Item
                        <font-awesome-icon
                          icon="chevron-right"
                          class="ft-14 ml-2"
                        />
                      </b-button>
                      <span class="ml-2 d-none d-md-inline-flex"
                        >Selected Item
                        {{ form.privilegeCode.privilegeDetail.length }}
                        List</span
                      >
                    </b-col>
                    <b-col md="4" cols="6" class="text-right">
                      <b-button
                        class="btn-select-branch w-100 w-sm-unset text-nowrap"
                        @click.prevent="createCoupon"
                      >
                        Create New Coupon
                      </b-button>
                    </b-col>
                  </b-row>
                  <div
                    v-if="$v.form.privilegeCode.privilegeDetail.$error"
                    class="my-3 text-error"
                  >
                    Please Select a Coupon. / Stampcard
                  </div>
                  <SelectedPrivilegeDetail
                    :fields="fields"
                    :busy="isBusy"
                    :items="form.privilegeCode.privilegeDetail"
                    @deletePrivilegeDetail="deletePrivilegeDetail"
                    :checkRedeem="checkRedeem"
                    :freezeItemsSelected="freezeItemsSelected"
                    @seeDetailCoupon="seeDetailCoupon"
                  />
                </b-container>
              </div>
            </div>
            <div class="no-gutters bg-white mt-3 py-2 px-3">
              <b-form-checkbox
                switch
                v-model="form.privilegeCode.status"
                class="radio-active"
                size="lg"
                :value="1"
                :unchecked-value="0"
              >
                <span class="ml-2 main-label">{{
                  form.privilegeCode.status ? "Active" : "Inactive"
                }}</span>
              </b-form-checkbox>
            </div>

            <FooterAction @submit="checkForm(0)" routePath="/privilegecode" />
          </b-tab>
          <b-tab title="Customer List" v-if="parseInt(id) > 0">
            <TabCustomerList
              placeholder="Search Customer Name"
              :filter="filterReport"
              :fields="fieldUsePrivilege"
              :items="itemUsePrivilege"
              :isBusy="isBusyUsePrivilege"
              :rows="rowsUsePrivilege"
              @exportExcel="exportExcel"
              :pageOptions="pageOptions"
              :showing="showing"
              :showingTo="showingTo"
              @getPage="getPage"
              @getPageOption="getPageOption"
              @sidebarFilter="sidebarFilter"
              @handleSearch="handleSearch"
            />
          </b-tab>
        </b-tabs>
      </b-form>
      <SidebarFilterUsedPrivilege
        ref="sidebarFilterUsedPrivilege"
        :filter="filterReport"
        @searchFilter="searchFilter"
      />
      <SidebarCoupon ref="sideBarCoupon" @couponSuccess="couponSuccess" />
      <ModalAddPrivilegeDetail
        ref="modalPrivilegeDetail"
        :form="form"
        @submitSelect="submitSelect"
        :id="id"
        :freezeItemsSelected="freezeItemsSelected"
        :checkRedeem="checkRedeem"
      />
    </div>
  </div>
</template>

<script>
import { required, helpers } from "vuelidate/lib/validators";
const alpha = helpers.regex("alpha", /^[0-9A-Z-]*$/);
import SelectedPrivilegeDetail from "@/components/privilegeDetail/SelectedPrivilegeDetail";
import ModalAddPrivilegeDetail from "@/components/privilegeDetail/ModalAddPrivilegeDetail";
import SidebarCoupon from "@/components/privilegeDetail/sidebar/SidebarCoupon";
import FormPrivilege from "@/components/privilegeDetail/detail/FormPrivilege";
import TabCustomerList from "@/components/coupon/detail/customer/TabCustomerList";
import SidebarFilterUsedPrivilege from "@/components/privilegeDetail/detail/SidebarFilterUsedPrivilege";
import minValue from "vuelidate/lib/validators/minValue";
const greaterThanZero = (value) => value > 0;
export default {
  name: "PrivilegeCodeDetails",
  components: {
    SelectedPrivilegeDetail,
    ModalAddPrivilegeDetail,
    SidebarCoupon,
    FormPrivilege,
    TabCustomerList,
    SidebarFilterUsedPrivilege,
  },
  data() {
    return {
      id: this.$route.params.id,
      isLoading: false,
      isBusy: false,
      isDisable: false,
      coverImgType: 1,
      showVideo: "",
      showPreview: "",
      showing: 1,
      showingTo: 5,
      showSidebar: false,
      form: {
        privilegeCode: {
          id: 0,
          prefix: "",
          name: "",
          status: false,
          image_url: "",
          short_description: "",
          description: "",
          detail: "",
          quantity: 0,
          privilegeDetail: [],
          valid_from: "",
          valid_to: "",
          user_limit: 0,
          total_redeem_day: 0,
        },
        productId: [],
      },
      newPrivilegeDetail: {},
      filter: {
        take: 5,
        page: 1,
      },
      rows: 0,
      fields: [
        {
          key: "image_url",
          label: "Image",
          tdClass: "text-center",
          class: "w-200",
        },
        {
          key: "name",
          label: "Coupon Name / Stampcard",
          tdClass: "text-left",
          thClass: "w-200",
        },
        {
          key: "prefix",
          label: "CODE",
          thClass: "w-100px",
        },
        {
          key: "valid_from",
          label: "Valid From",
        },
        {
          key: "valid_to",
          label: "Valid To",
        },

        {
          key: "see_detail",
          label: "",
        },
        {
          key: "action",
          label: "",
        },
      ],
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      refreshInput: true,
      checkRedeem: false,

      idCoupon: 0,
      fieldUsePrivilege: [
        {
          label: "Name-Surname",
          key: "name",
        },
        {
          label: "Privilege Code",
          key: "code",
        },
        {
          label: "Date / Time Redeemed",
          key: "redeemed_date_string",
        },
      ],
      itemUsePrivilege: [],
      isBusyUsePrivilege: true,
      rowsUsePrivilege: 0,
      filterReport: {
        redeem_date: null,
        search: "",
        take: 10,
        page: 1,
        privilege_id: 0,
      },
      filterExport: {
        redeem_date: null,
        search: "",
        privilege_id: 0,
      },
      freezeItemsSelected: [],
      quantityIsRedeem: 0,
    };
  },
  validations() {
    return {
      form: {
        privilegeCode: {
          name: { required },
          prefix: { required },
          image_url: { required },
          short_description: { required },
          description: { required },
          quantity: {
            required,
            minValue: minValue(this.quantityIsRedeem),
          },
          valid_from: { required },
          valid_to: { required },
          privilegeDetail: { required },

          // sort_order: { required },
        },
      },
    };
  },
  watch: {},
  created: async function () {
    if (this.id !== "0") {
      await this.getData();
      await this.getPrivilegeReport();
    }
    this.$v.$reset();
  },
  destroyed() {},
  methods: {
    createCoupon(val) {
      this.$refs.sideBarCoupon.show(0);
    },
    searchFilter(value) {
      this.filterReport = value;
      this.getPrivilegeReport();
    },
    handleSearch(value) {
      this.filterReport = value;
      this.getPrivilegeReport();
    },
    sidebarFilter() {
      this.$refs.sidebarFilterUsedPrivilege.show();
    },
    getPageOption(value) {
      this.filterReport = value;
      this.getPrivilegeReport();
    },
    getPage(value) {
      this.filterReport = value;
      this.getPrivilegeReport();
    },
    async exportExcel() {
      this.filterExport.redeem_date = this.filterReport.redeem_date;
      this.filterExport.search = this.filterReport.search;
      this.filterExport.privilege_id = parseInt(this.id);
      await this.$store.dispatch(
        "ActionExportPrivilegeReport",
        this.filterExport
      );
      const data =
        this.$store.state.modulePrivilegeCode.stateExportPrivilegeReport;
      if (data.status == 200) {
        var fileURL = window.URL.createObjectURL(new Blob([data.data])); // change object.
        var fileLink = document.createElement("a");
        var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `Customer_Used_Privilege-` + dateExcel + `.xlsx`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
      } else {
        alert("Export fail");
      }
      this.isLoading = false;
    },
    async getPrivilegeReport() {
      this.isBusyUsePrivilege = true;
      this.filterReport.privilege_id = parseInt(this.id);
      await this.$store.dispatch(
        "ActionGetUsedPrivilegeReport",
        this.filterReport
      );
      const data =
        this.$store.state.modulePrivilegeCode.stateGetUsedPrivilegeReport;
      if (data) {
        this.itemUsePrivilege = data.detail;
        this.rowsUsePrivilege = data.total_count;
        this.isBusyUsePrivilege = false;
      }
    },
    closeSidebar() {
      this.$refs.sideBarCoupon.hide();
    },
    deletePrivilegeDetail(data) {
      const findPrivilegeDetail =
        this.form.privilegeCode.privilegeDetail.findIndex(
          (el) =>
            el.reference_id === data.item.reference_id &&
            data.item.privilege_type_id == el.privilege_type_id
        );
      this.form.privilegeCode.privilegeDetail.splice(findPrivilegeDetail, 1);
      for (const index in this.form.privilegeCode.privilegeDetail) {
        this.form.privilegeCode.privilegeDetail[index].sort_order =
          parseInt(index) + 1;
      }
    },
    submitSelect(value) {
      this.form.privilegeCode.privilegeDetail = [...value];
    },
    getData: async function () {
      await this.$store.dispatch("ActionGetPrivilegeCodeByID", this.id);
      var data = await this.$store.state.modulePrivilegeCode
        .stateGetPrivilegeCodeByID;
      if (data.result == 1) {
        for (let i = 0; i < data.detail.detail.privilegeDetail.length; i++) {
          const element = data.detail.detail.privilegeDetail[i];
          element.fakeID = i;
        }
        this.form.privilegeCode = data.detail.detail;
        this.freezeItemsSelected = [...data.detail.detail.privilegeDetail];
        this.quantityIsRedeem = data.detail.detail.quantity;
        // this.form.privilegeCode.PrivilegeDetail = data.detail.detail.privilegeDetail
        this.rows = this.form.privilegeCode.privilegeDetail.length;
        if (this.form.privilegeCode.is_redeem > 0) {
          this.checkRedeem = true;
        } else {
          this.checkRedeem = false;
        }
        this.showPreview = data.detail.detail.image_url;
      }
    },
    dateFormat() {
      let today = new Date();
      return this.$moment(today).format();
    },
    AlertDelete(data) {
      this.$swal({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this item!",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          this.deleteData(data);
        }
      });
    },
    deleteData: async function (data) {
      const even = (element) => element.id === data.id;
      var ischeck = this.form.privilegeCode.privilegeDetail.some(even);
      const condition = (element) => element.id === data.id;
      var idIndex =
        this.form.privilegeCode.privilegeDetail.findIndex(condition);
      if (ischeck) {
        this.form.privilegeCode.privilegeDetail.splice(idIndex, 1);
      }
    },
    checkForm: async function () {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.submit();
    },
    submit: async function () {
      if (this.form.privilegeCode.status == false) {
        this.form.privilegeCode.status = 0;
      } else {
        this.form.privilegeCode.status = 1;
      }
      this.isDisable = true;
      var data = null;
      for (const detail of this.form.privilegeCode.privilegeDetail) {
        if (detail.coupon_id) {
          delete detail.coupon_id;
        }
      }
      this.$bus.$emit("showLoading");
      if (this.id !== "0") {
        await this.$store.dispatch(
          "ActionUpdatePrivilegeCode",
          this.form.privilegeCode
        );
        data = await this.$store.state.modulePrivilegeCode
          .stateUpdatePrivilegeCode;
        if (data.result == 1) {
          this.$bus.$emit("hideLoading");
          this.successAlert().then(() => {
            this.$router.push("/privilegecode");
          });
          this.isDisable = false;
        } else if (data.result == 0) {
          this.$bus.$emit("hideLoading");
          this.errorAlert(data.message);
          this.isDisable = false;
        }
      } else {
        await this.$store.dispatch(
          "ActionCreatePrivilegeCode",
          this.form.privilegeCode
        );
        data = await this.$store.state.modulePrivilegeCode
          .stateCreatePrivilegeCode;
        if (data.result == 1) {
          this.$bus.$emit("hideLoading");
          this.successAlert().then(() => {
            this.$router.push("/privilegecode");
          });
          this.isDisable = false;
        } else if (data.result == 0) {
          this.$bus.$emit("hideLoading");
          this.errorAlert(data.message);
          this.isDisable = false;
        }
        this.$bus.$emit("hideLoading");
      }
    },
    selectPrivilegeDetail() {
      this.$refs.modalPrivilegeDetail.show(true);
    },
    async couponSuccess(item) {
      if (item.type === "created") {
        let filter = {
          id: 1,
          search: "",
          page: 1,
          take: 1,
          reference_id: 0,
          show_only_select: 0,
          item_select: [],
        };
        await this.$store.dispatch("ActionGetPrivilegeCondition", filter);
        const data = await this.$store.state.modulePrivilegeCode
          .stateGetPrivilegeCondition;

        if (data.result == 1) {
          let body = {
            id: item.id,
            name: data.detail.detail[0].name ? data.detail.detail[0].name : "",
            image_url: data.detail.detail[0].image_url
              ? data.detail.detail[0].image_url
              : "",
            privilege_type_id: 1,
            reference_id: data.detail.detail[0].id,
            prefix: data.detail.detail[0].prefix
              ? data.detail.detail[0].prefix
              : "",
            valid_from: data.detail.detail[0].validFrom,
            valid_to: data.detail.detail[0].validTo,
            sort_order: this.form.privilegeCode.privilegeDetail.length + 1,
            description: data.detail.detail[0].description,
            short_description: data.detail.detail[0].short_description,
          };

          this.form.privilegeCode.privilegeDetail.push(body);
        }
      }
    },
    seeDetailCoupon(id) {
      this.$refs.sideBarCoupon.show(id);
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-confirm-automation {
  color: #fff;
  border-radius: 0px;
  border-color: var(--primary-color);
  background-color: var(--primary-color);
  width: 30%;
}
.btn-cancel-automation {
  color: #fff !important;
  background-color: #39424e;
  border-radius: 0px;
  width: 30%;
  border: 1px solid #39424e;
}
.btn-select-sidebar {
  width: 100%;
  max-width: fit-content;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  text-align: center;
}

::v-deep .nav-tabs .nav-link:not(.active):hover {
  background-color: transparent;
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}
.panelborder {
  border: 1px solid #d8dbe0;
}
.w-sm-unset {
  width: unset !important;
}
</style>
